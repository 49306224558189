<template>
    <page-layout-nolo>
        <v-container class="fill-height pa-8 inbound-container-page">
            <v-row no-gutters>
                <v-col cols="12" class="nolo-p1 text-center pb-4">
                    {{ $t('message.youreAt', { storeName: store.name })}}
                </v-col>
                <v-col cols="12" class="nolo-h3 text-center px-0 pb-4 px-sm-10">
                    {{ $t('whatsYourTable')}}
                </v-col>
                <v-col cols="12" md="6" offset-md="3">
                    <v-form v-model="validForm">
                        <v-text-field outlined full-width type="number" min="1" max="99"
                                      class="centered-input numeric-input" background-color="white"
                                      v-model="sessTableNumber"
                                      :rules="[inputRules.required, inputRules.tableNumber]"
                                      :placeholder="$t('label.tableNumber')"/>
                    </v-form>
                </v-col>
                <v-col cols="12">
                    <v-btn @click="orderMyFood"
                           large color="primary" depressed
                           :disabled="!validForm || isLoading" :loading="isLoading" block>{{ $t('btn.orderMyFood') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </page-layout-nolo>
</template>

<script>
    import AppMixin from "../../mixins/AppMixin";
    import APIStore from "../../api/APIStore";
    import Constants from "../../api/Constants";

    export default {
        name: "TableService",
        data() {
            return {
                isLoading: true,
                validForm: false
            }
        },
        mixins: [AppMixin],
        mounted() {
            if (this.store === null) {
                this.$router.replace({name: '404'});
            }
            if (!this.validateOrderMode()) {
                return;
            }

            if (!this.keepSession) {
                this.clearBasket();
            }
            const me = this;
            APIStore.getFulfilmentTimesFor(this, this.store.id, Constants.OrderType.Table)
                .then((response) => {
                    me.fulfilmentTimes = response.data.data.fulfilmentTimes[0];
                    this.$store.commit('session/latestFulfilmentTime', this.latestFulfilmentTime);

                    me.isLoading = false;
                });

        },
        methods: {
            andQuitQuickStart() {
                this.$router.push({name: 'start-order'});
            },
            validateOrderMode() {
                // Is it available at this store?
                if (!this.store.tableAvailable) {
                    this.appShowErrorDialog(this.$i18n.t('error.tableModeUnavailable'), 'error', this.andQuitQuickStart);
                    return false;
                }
                // is the store accepting orders
                if (!this.store.online) {
                    this.appShowErrorDialog(this.store.offlineReason ? this.store.offlineReason : this.i18n.t('message.defaultOfflineReason'), 'error', this.andQuitQuickStart);
                    return false;
                }
                return true;
            },
            orderMyFood() {
                const me = this;
                let today = this.now.date;
                let reorderId = this.$store.getters['session/reorder'];

                if (this.keepSession) {
                    me.$router.push('/order');
                } else {
                    this.$store.dispatch('session/resetProgress')
                        .then(() => {
                            me.$store.commit('session/reorder', reorderId);
                            me.$store.commit('session/location', '.ANYWHERE');
                            me.$store.commit('session/gpsLocation', {lat: 0, lng: 0});
                            me.$store.commit('session/orderType', 'Table');
                            me.$store.commit('session/storeId', this.store.id);
                            me.$store.commit('session/fulfilmentDate', {date: today});
                            me.$store.commit('session/fulfilmentTime', {time: -1});
                            me.$router.push('/order');
                        })
                }
            }
        },
        computed: {
            fulfilmentTimes: {
                get() { return this.$store.getters['session/fulfilmentTimes']; },
                set(newValue) { return this.$store.commit('session/fulfilmentTimes', newValue); }
            },
            latestFulfilmentTime() {
                let times = this.fulfilmentTimes;
                if (times.length === 0) return null;

                return times[times.length - 1].time;
            },
            keepSession() {
                return this.$route.name === 'table-service-change';
            },
            sessTableNumber: {
                get() {
                    return this.$store.getters['session/tableNumber'];
                },
                set(newValue) {
                    return this.$store.commit('session/tableNumber', newValue);
                }
            },
            stores() {
                return this.$store.getters['app/stores'];
            },
            store() {
                let matchCode = this.$route.params.storeId.toUpperCase();
                for (let i in Object.keys(this.stores)) {
                    let key = Object.keys(this.stores)[i];
                    if (this.stores[key].code === matchCode) return this.stores[key];
                }
                return null;
            }
        }
    }
</script>

<style scoped>

.centered-input >>> input, .centered-input >>> label {
    text-align: center;
}

</style>
